import React from 'react';
import {UserProvider} from '@auth0/nextjs-auth0/client';
import ThemeProvider from '@providers/ThemeProvider';

// Note: this is the implementation for a single shared layout
// If you need multiple layouts see https://nextjs.org/docs/basic-features/layouts#per-page-layouts

import Layout from '@components/Layout';

const AppRoot: React.FC<{children?: React.ReactNode}> = ({children}) => (
	<UserProvider>
		<ThemeProvider>
			<Layout>{children}</Layout>
		</ThemeProvider>
	</UserProvider>
);

export default AppRoot;
