import React, {useState} from 'react';
import {
	Box,
	VStack,
	Text,
	Flex,
	Button,
	Avatar,
	IconButton,
	DeezerLogo,
} from '@tempo/core';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	HumansIcon,
	LogoutIcon,
	EqualIcon,
	CrossIcon,
} from '@tempo/icons';
import {useRouter} from 'next/router';
import Link from 'next/link';
import {useUser} from '@auth0/nextjs-auth0/client';
import {DeezerPartnersLogo} from '@components/DeezerPartnersLogo';
import useWindowSize from '@hooks/useWindowSize';

type Props = {
	sidebarWidth: number;
	onToggle: (isOpen: boolean) => void;
};

const Sidebar = ({sidebarWidth, onToggle}: Props) => {
	const {user} = useUser();
	const isOpen = sidebarWidth === 272;
	const router = useRouter();
	const windowSize = useWindowSize();
	const isMobile = windowSize.width !== undefined && windowSize.width <= 768;
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const navItems = [
		{
			name: 'Customer Care',
			path: '/',
			icon: <HumansIcon size="medium" />,
			dataTestId: 'customer-care',
		},
	];

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	if (isMobile) {
		return (
			<>
				<Box
					position="fixed"
					top={0}
					left={0}
					right={0}
					height="80px"
					bg="background.neutral.secondary.default"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					paddingX="spacing.l"
					zIndex={1000}
					borderBottom="1px"
					borderColor="border.neutral.primary.default"
				>
					<DeezerLogo iconOnly={true} height="32px" data-testid="deezer-logo" />
					<IconButton
						data-testid={
							isMobileMenuOpen ? 'fold-button-mobile' : 'unfold-button-mobile'
						}
						variant="ghost"
						icon={isMobileMenuOpen ? <CrossIcon /> : <EqualIcon />}
						aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
						onClick={toggleMobileMenu}
					/>
				</Box>
				{isMobileMenuOpen && (
					<Box
						position="fixed"
						top="80px"
						left={0}
						right={0}
						bottom={0}
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
						bg="background.neutral.secondary.default"
						zIndex={999}
						overflowY="auto"
					>
						<VStack
							align="stretch"
							spacing="spacing.s"
							paddingX="spacing.s"
							paddingY="spacing.l"
						>
							{navItems.map((item) => (
								<Link href={item.path} key={item.name} passHref>
									<Box
										data-testid={item.dataTestId}
										borderRadius="s"
										background={
											router.pathname === item.path
												? 'background.neutral.secondary.selected'
												: 'transparent'
										}
										_hover={{
											background: 'background.neutral.secondary.selected',
										}}
										display="flex"
										gap="spacing.s"
										p="spacing.s"
										color={
											router.pathname === item.path
												? 'text.neutral.primary.selected'
												: 'text.neutral.primary.default'
										}
										fontSize="18px"
										fontStyle="normal"
										fontWeight={700}
										onClick={() => setIsMobileMenuOpen(false)}
									>
										{item.icon}
										<Box>{item.name}</Box>
									</Box>
								</Link>
							))}
						</VStack>
						<Flex justifyContent="flex-end" flexDirection="column">
							{user && (
								<Box
									padding="spacing.l"
									display="flex"
									gap="spacing.s"
									alignItems="center"
									justifyContent="space-between"
									borderTop="1px"
									borderColor="border.neutral.primary.default"
								>
									<Flex alignItems="center" gap="spacing.s">
										<Avatar
											data-testid="user-avatar"
											size="size.m"
											bg="background.neutral.tertiary.default"
											name={user?.name || ''}
										/>
										<Box>
											<Text
												color="text.neutral.primary.default"
												isTruncated
												maxWidth="130px"
											>
												{user.name}
											</Text>
										</Box>
									</Flex>
									<a href="/api/auth/logout">
										<IconButton
											data-testid="logout-button"
											color="icon.neutral.secondary.default"
											icon={<LogoutIcon size="medium" />}
											variant="ghost"
											aria-label="Logout"
										/>
									</a>
								</Box>
							)}
						</Flex>
					</Box>
				)}
			</>
		);
	}

	return (
		<Box
			borderRight="1px"
			borderColor="border.neutral.primary.default"
			position="fixed"
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			left={0}
			w={`${sidebarWidth}px`}
			top={0}
			h="100vh"
			bg="background.neutral.secondary.default"
			transition="width 0.3s ease"
		>
			<VStack align="stretch" spacing="spacing.l">
				{isOpen ? (
					<Box
						gap="spacing.m"
						display="flex"
						alignItems="center"
						paddingX="spacing.l"
						paddingY="spacing.xl"
					>
						<DeezerLogo
							iconOnly={true}
							height="32px"
							data-testid="deezer-logo"
						/>
						<Box
							width="1px"
							bg="divider.neutral.primary.default"
							height="32px"
						/>
						<DeezerPartnersLogo dataTestId="partner-logo" height={32} />
					</Box>
				) : (
					<Box
						display="flex"
						alignItems="center"
						paddingX="spacing.l"
						paddingY="spacing.xl"
					>
						<DeezerLogo
							data-testid="deezer-logo"
							iconOnly={true}
							height="32px"
						/>
					</Box>
				)}
				<VStack align="stretch" spacing="spacing.s" paddingX="spacing.s">
					{navItems.map((item) => (
						<Link href={item.path} key={item.name} passHref>
							<Box
								data-testid={item.dataTestId}
								borderRadius="s"
								background={
									router.pathname === item.path
										? 'background.neutral.secondary.selected'
										: 'transparent'
								}
								_hover={{
									background: 'background.neutral.secondary.selected',
								}}
								display="flex"
								gap="spacing.s"
								p="spacing.s"
								color={
									router.pathname === item.path
										? 'text.neutral.primary.selected'
										: 'text.neutral.primary.default'
								}
								fontSize="18px"
								fontStyle="normal"
								fontWeight={700}
							>
								{item.icon}
								{isOpen && <Box>{item.name}</Box>}
							</Box>
						</Link>
					))}
				</VStack>
			</VStack>

			<Flex justifyContent="flex-end" flexDirection="column">
				{isOpen ? (
					<Button
						data-testid="fold-button"
						alignSelf="end"
						w="fit-content"
						variant="ghost"
						aria-label="Close sidebar"
						marginBottom="spacing.s"
						marginRight="spacing.s"
						leftIcon={
							<ChevronLeftIcon
								color="icon.neutral.secondary.default"
								aria-label="Open sidebar"
							/>
						}
						onClick={() => onToggle(false)}
					>
						Fold
					</Button>
				) : (
					<IconButton
						data-testid="unfold-button"
						alignSelf="center"
						variant="ghost"
						color="icon.neutral.secondary.default"
						aria-label="Open sidebar"
						marginBottom="spacing.s"
						icon={<ChevronRightIcon />}
						onClick={() => onToggle(true)}
					/>
				)}
				<Box height="1px" width="100%" bg="divider.neutral.primary.default" />
				{user && (
					<Box
						width="full"
						padding="spacing.l"
						display="flex"
						gap="spacing.s"
						alignItems="center"
						justifyContent={isOpen ? 'space-between' : 'center'}
					>
						{isOpen && (
							<Box display="flex" gap="spacing.s" alignItems="center">
								<Avatar
									data-testid="user-avatar"
									size="size.m"
									bg="background.neutral.tertiary.default"
									name={user?.name || ''}
								/>
								<Box>
									<Text
										data-testid="username"
										color="text.neutral.primary.default"
										isTruncated
										maxWidth="150px"
									>
										{user.name}
									</Text>
								</Box>
							</Box>
						)}

						<a href="/api/auth/logout">
							<IconButton
								color="icon.neutral.secondary.default"
								icon={<LogoutIcon size="medium" />}
								variant="ghost"
								aria-label="Logout"
								data-testid="logout-button"
							/>
						</a>
					</Box>
				)}
			</Flex>
		</Box>
	);
};

export default Sidebar;
