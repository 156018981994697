import React from 'react';
import Head from 'next/head';
import type {AppProps} from 'next/app';
import {useRouter} from 'next/router';
import {appWithTranslation} from 'next-i18next';
import configNextI18Next from 'next-i18next.config';
import {config} from '@modules/config';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import {init as initSentry} from '@sentry/react';

import AppRoot from '@components/AppRoot';

import './global.scss';

if (typeof window !== 'undefined' && process.env.SENTRY_DSN) {
	initSentry({
		enabled: process.env.NODE_ENV === 'production',
		dsn: process.env.SENTRY_DSN,
	});
}

const App: React.FC<AppProps> = ({Component, pageProps}) => {
	const {locale, locales, route} = useRouter();

	React.useEffect(() => {
		if (document.domain !== 'localhost') {
			try {
				// Used for some old browsers to enable some CORS stuff
				document.domain = config.get('host_cookie').slice(1);
			} catch (error) {
				// noop
			}
		}
	}, []);

	return (
		<>
			<Head>
				<>
					{(locales || [])
						.filter((l) => l !== locale)
						.map((locale) => (
							<link
								key={locale}
								rel="alternate"
								hrefLang={locale}
								href={`/${locale}${route}`}
							/>
						))}
				</>
			</Head>
			<AppRoot>
				<QueryClientProvider client={new QueryClient()}>
					<Component {...pageProps} />
				</QueryClientProvider>
			</AppRoot>
		</>
	);
};

export default appWithTranslation(App, configNextI18Next);
