import React from 'react';

type Props = {
	width?: number;
	height?: number;
	dataTestId?: string;
};
export const DeezerPartnersLogo: React.FC<Props> = (props) => {
	const {width = 116, height = 33, dataTestId} = props;

	return (
		<svg
			data-testid={dataTestId}
			width={width}
			height={height}
			viewBox="0 0 116 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.01536 0H0.966797V14.0606H8.01536C12.3796 14.0606 15.4657 11.1572 15.4657 7.0303C15.4657 2.90342 12.3796 0 8.01536 0ZM7.72319 10.2624H6.44495V3.79819H7.72319C9.09273 3.79819 9.82315 4.71122 9.82315 7.0303C9.82315 9.34939 9.09273 10.2624 7.72319 10.2624Z"
				fill="black"
			/>
			<path
				d="M16.4321 14.0606H28.0093V10.2624H21.892V8.52767H27.6441V5.42338H21.892V3.79819H28.0093V0H16.4321V14.0606Z"
				fill="black"
			/>
			<path
				d="M28.9761 14.0606H40.5532V10.2624H34.436V8.52767H40.188V5.42338H34.436V3.79819H40.5532V0H28.9761V14.0606Z"
				fill="black"
			/>
			<path
				d="M41.6296 3.79819H47.2821C44.9369 5.71701 43.0068 7.89037 41.52 10.2624V14.0606H53.9554V10.2624H47.7399C49.1642 8.19021 51.1545 6.12677 53.9554 3.79819V0H41.6296V3.79819Z"
				fill="black"
			/>
			<path
				d="M54.9219 14.0606H66.4991V10.2624H60.3818V8.52767H66.1338V5.42338H60.3818V3.79819H66.4991V0H54.9219V14.0606Z"
				fill="black"
			/>
			<path
				d="M81.8186 14.0606C81.0297 11.9106 79.9263 9.61654 78.442 7.04509C80.1779 6.53599 81.216 5.44164 81.216 3.76167C81.216 1.20519 78.8604 0 75.1352 0H67.4658V14.0606H72.944V8.24445C74.161 10.2944 75.0578 12.2287 75.6465 14.0606H81.8186ZM72.944 6.28162V3.79819H74.8431C75.6465 3.79819 76.1031 4.23644 76.1031 5.03991C76.1031 5.84337 75.6465 6.28162 74.8431 6.28162H72.944Z"
				fill="black"
			/>
			<path
				d="M110.066 22.7302C109.735 21.0282 109.016 20.1772 107.908 20.1772C107.565 20.1772 107.259 20.2476 106.989 20.3884C106.72 20.5293 106.509 20.7252 106.356 20.9762C106.209 21.2272 106.135 21.5088 106.135 21.821C106.135 22.2006 106.245 22.5251 106.466 22.7945C106.686 23.0639 107.06 23.3332 107.586 23.6026L109.974 24.6036C110.923 25.0628 111.627 25.6046 112.086 26.229C112.551 26.8535 112.784 27.5667 112.784 28.3688C112.784 29.1402 112.582 29.8381 112.178 30.4626C111.774 31.087 111.217 31.5799 110.506 31.9411C109.802 32.2962 109.016 32.4737 108.146 32.4737C107.283 32.4737 106.506 32.2809 105.814 31.8952C105.128 31.5095 104.553 30.9462 104.087 30.2054C103.622 29.4585 103.285 28.5616 103.077 27.5147H105.823C105.988 28.4024 106.267 29.0759 106.659 29.535C107.05 29.9942 107.54 30.2238 108.128 30.2238C108.483 30.2238 108.798 30.1534 109.074 30.0126C109.355 29.8656 109.576 29.6636 109.735 29.4065C109.894 29.1432 109.974 28.8494 109.974 28.5249C109.974 28.1514 109.864 27.83 109.643 27.5606C109.423 27.2851 109.056 27.0157 108.541 26.7525L106.163 25.7423C105.202 25.2832 104.491 24.7444 104.032 24.1261C103.579 23.5077 103.353 22.7975 103.353 21.9955C103.353 21.2425 103.552 20.5568 103.95 19.9385C104.354 19.314 104.902 18.8242 105.593 18.4691C106.291 18.1079 107.063 17.9273 107.908 17.9273C109.144 17.9273 110.185 18.3436 111.03 19.1762C111.875 20.0027 112.466 21.1874 112.802 22.7302H110.066Z"
				fill="black"
			/>
			<path
				d="M93.0352 18.1293H97.5717C98.5268 18.1293 99.387 18.3008 100.152 18.6436C100.918 18.9864 101.514 19.4609 101.943 20.067C102.378 20.6731 102.595 21.3557 102.595 22.1149C102.595 22.7455 102.445 23.3179 102.145 23.8322C101.845 24.3465 101.416 24.775 100.859 25.1179C100.308 25.4546 99.6625 25.6811 98.9217 25.7974C99.6625 26.8076 100.348 27.8392 100.979 28.8922C101.615 29.9391 102.225 31.0656 102.806 32.2717H99.5645C99.0013 31.0411 98.4197 29.9299 97.8197 28.9381C97.2258 27.9463 96.5769 27.0127 95.8728 26.1372V32.2717H93.0352V18.1293ZM97.4891 24.576C98.2115 24.576 98.7717 24.3924 99.1696 24.025C99.5676 23.6577 99.7665 23.1404 99.7665 22.4731C99.7665 21.8057 99.5676 21.2884 99.1696 20.9211C98.7717 20.5537 98.2115 20.3701 97.4891 20.3701H95.8728V24.576H97.4891Z"
				fill="black"
			/>
			<path
				d="M91.9089 18.1293V20.4803H86.9315V24.0067H91.5507V26.2841H86.9315V29.9207H91.9089V32.2717H84.103V18.1293H91.9089Z"
				fill="black"
			/>
			<path
				d="M73.3052 18.1293H76.0418C77.6887 21.3925 79.057 24.2944 80.1468 26.8351V18.1293H82.7548V32.2717H80.0274C78.9131 29.4065 77.5479 26.3117 75.9316 22.9873V32.2717H73.3052V18.1293Z"
				fill="black"
			/>
			<path
				d="M72.4094 20.4986H68.9932V32.2717H66.1647V20.4986H62.7485V18.1293H72.4094V20.4986Z"
				fill="black"
			/>
			<path
				d="M53.1089 18.1293H57.6455C58.6005 18.1293 59.4607 18.3008 60.226 18.6436C60.9912 18.9864 61.5882 19.4609 62.0167 20.067C62.4514 20.6731 62.6687 21.3557 62.6687 22.1149C62.6687 22.7455 62.5187 23.3179 62.2188 23.8322C61.9188 24.3465 61.4902 24.775 60.9331 25.1179C60.3821 25.4546 59.7362 25.6811 58.9954 25.7974C59.7362 26.8076 60.4219 27.8392 61.0525 28.8922C61.6892 29.9391 62.2983 31.0656 62.88 32.2717H59.6382C59.075 31.0411 58.4934 29.9299 57.8934 28.9381C57.2995 27.9463 56.6506 27.0127 55.9465 26.1372V32.2717H53.1089V18.1293ZM57.5628 24.576C58.2852 24.576 58.8454 24.3924 59.2434 24.025C59.6413 23.6577 59.8403 23.1404 59.8403 22.4731C59.8403 21.8057 59.6413 21.2884 59.2434 20.9211C58.8454 20.5537 58.2852 20.3701 57.5628 20.3701H55.9465V24.576H57.5628Z"
				fill="black"
			/>
			<path
				d="M41.8696 32.2717C42.7574 27.5147 43.8196 22.8006 45.0562 18.1293H49.0694C50.2754 22.6781 51.3376 27.3923 52.256 32.2717H49.3265C49.2285 31.635 49.0755 30.6738 48.8673 29.3881H45.2032C45.044 30.3248 44.8879 31.286 44.7348 32.2717H41.8696ZM48.4816 27.2025C48.0347 24.7903 47.5511 22.4761 47.0307 20.2599C46.5042 22.5312 46.0236 24.8454 45.5889 27.2025H48.4816Z"
				fill="black"
			/>
			<path
				d="M32.9849 18.1293H37.5398C38.5194 18.1293 39.3918 18.3222 40.157 18.7079C40.9284 19.0875 41.5284 19.617 41.957 20.2966C42.3855 20.9762 42.5998 21.7506 42.5998 22.62C42.5998 23.4771 42.3855 24.2454 41.957 24.925C41.5284 25.5984 40.9284 26.125 40.157 26.5045C39.3918 26.8841 38.5194 27.0739 37.5398 27.0739H35.8225V32.2717H32.9849V18.1293ZM37.4388 24.9158C38.1796 24.9158 38.752 24.7199 39.1561 24.3281C39.5601 23.9363 39.7622 23.3822 39.7622 22.6659C39.7622 21.9435 39.5601 21.3864 39.1561 20.9945C38.752 20.6027 38.1796 20.4068 37.4388 20.4068H35.8225V24.9158H37.4388Z"
				fill="black"
			/>
			<path
				d="M19.6045 18.1293H24.1411C25.0961 18.1293 25.9563 18.3008 26.7216 18.6436C27.4869 18.9864 28.0838 19.4609 28.5123 20.067C28.947 20.6731 29.1643 21.3557 29.1643 22.1149C29.1643 22.7455 29.0143 23.3179 28.7144 23.8322C28.4144 24.3465 27.9858 24.775 27.4287 25.1179C26.8777 25.4546 26.2318 25.6811 25.491 25.7974C26.2318 26.8076 26.9175 27.8392 27.5481 28.8922C28.1848 29.9391 28.7939 31.0656 29.3756 32.2717H26.1338C25.5706 31.0411 24.989 29.9299 24.389 28.9381C23.7952 27.9463 23.1462 27.0127 22.4421 26.1372V32.2717H19.6045V18.1293ZM24.0584 24.576C24.7808 24.576 25.341 24.3924 25.739 24.025C26.1369 23.6577 26.3359 23.1404 26.3359 22.4731C26.3359 21.8057 26.1369 21.2884 25.739 20.9211C25.341 20.5537 24.7808 20.3701 24.0584 20.3701H22.4421V24.576H24.0584Z"
				fill="black"
			/>
			<path
				d="M13.3343 32.4737C12.318 32.4737 11.418 32.1707 10.6344 31.5646C9.85073 30.9585 9.24157 30.1075 8.8069 29.0116C8.37834 27.9096 8.16406 26.6392 8.16406 25.2005C8.16406 23.7618 8.37834 22.4945 8.8069 21.3986C9.24157 20.2966 9.85073 19.4426 10.6344 18.8365C11.418 18.2304 12.318 17.9273 13.3343 17.9273C14.3444 17.9273 15.2414 18.2304 16.025 18.8365C16.8086 19.4426 17.4178 20.2966 17.8525 21.3986C18.2872 22.4945 18.5045 23.7618 18.5045 25.2005C18.5045 26.6392 18.2872 27.9096 17.8525 29.0116C17.4178 30.1075 16.8086 30.9585 16.025 31.5646C15.2414 32.1707 14.3444 32.4737 13.3343 32.4737ZM11.1027 25.2005C11.1027 26.8474 11.2925 28.078 11.6721 28.8922C12.0578 29.7003 12.6119 30.1044 13.3343 30.1044C14.0567 30.1044 14.6077 29.7003 14.9873 28.8922C15.373 28.078 15.5658 26.8474 15.5658 25.2005C15.5658 23.5536 15.373 22.3261 14.9873 21.518C14.6077 20.7037 14.0567 20.2966 13.3343 20.2966C12.6119 20.2966 12.0578 20.7037 11.6721 21.518C11.2925 22.3261 11.1027 23.5536 11.1027 25.2005Z"
				fill="black"
			/>
			<path
				d="M7.68645 18.1293V20.4435H2.83765V24.1444H7.35585V26.3576H2.83765V32.2717H0V18.1293H7.68645Z"
				fill="black"
			/>
		</svg>
	);
};
