import type {ReactNode} from 'react';
import React, {useState} from 'react';
import Head from 'next/head';

import {Box} from '@tempo/core';
import Sidebar from '@components/Sidebar';
import {useUser} from '@auth0/nextjs-auth0/client';
import useWindowSize from '@hooks/useWindowSize';

type Props = {
	children?: ReactNode;
};

const Layout: React.FC<Props> = ({children}) => {
	const {user} = useUser();
	const [sidebarWidth, setSidebarWidth] = useState(272); // Default open width
	const windowSize = useWindowSize();
	const isMobile = windowSize.width !== undefined && windowSize.width <= 768;

	const handleSidebarToggle = (isOpen: boolean) => {
		setSidebarWidth(isOpen ? 272 : 60);
	};

	if (!user) {
		return (
			<>
				<Head>
					<title>Partner Portal</title>
					<meta charSet="utf-8" />
					<meta
						name="viewport"
						content="initial-scale=1.0, width=device-width"
					/>
				</Head>
				{children}
			</>
		);
	}

	return (
		<>
			<Head>
				<title>Partner Portal</title>
				<meta charSet="utf-8" />
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			</Head>
			<Box display="flex">
				<Sidebar sidebarWidth={sidebarWidth} onToggle={handleSidebarToggle} />
				<Box
					as="main"
					flex={1}
					marginLeft={isMobile ? 0 : `${sidebarWidth}px`}
					marginTop={isMobile ? '80px' : 0}
					transition="margin-left 0.3s ease"
				>
					{children}
				</Box>
			</Box>
		</>
	);
};

export default Layout;
